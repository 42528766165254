var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":_vm.locationBreadcrumbs}}),_c('v-card',{staticClass:"overflow-hidden"},[_c('div',{staticClass:"d-flex px-3 pt-3"},[_c('v-btn',{attrs:{"disabled":_vm.parentPath === null,"depressed":""},on:{"click":function($event){return _vm.navigateToPath(_vm.parentPath)}}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-spacer'),_c('input',{ref:"fileUploadInput",staticClass:"d-none",attrs:{"type":"file"},on:{"input":_vm.uploadFile}}),_c('v-btn',{staticClass:"mr-1",attrs:{"depressed":""},on:{"click":function($event){return _vm.$refs.fileUploadInput.click()}}},[_c('v-icon',[_vm._v("mdi-file-upload-outline")])],1),_c('v-menu',{attrs:{"offset-y":"","attach":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"depressed":""}},on),[_c('v-icon',[_vm._v("mdi-folder-plus-outline")])],1)]}}]),model:{value:(_vm.createDirectoryDialogOpen),callback:function ($$v) {_vm.createDirectoryDialogOpen=$$v},expression:"createDirectoryDialogOpen"}},[_c('v-card',[_c('h4',{staticClass:"pa-3 my-0"},[_vm._v("Create directory")]),_c('v-text-field',{attrs:{"error-messages":_vm.createDirectoryErrorMessages,"hide-details":_vm.createDirectoryErrorMessages.length === 0,"solo":"","flat":"","autofocus":"","append-icon":"mdi-check","label":"Name"},on:{"click:append":_vm.createDirectory,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createDirectory($event)}},model:{value:(_vm.newDirectoryName),callback:function ($$v) {_vm.newDirectoryName=$$v},expression:"newDirectoryName"}})],1)],1),_c('v-btn-toggle',{staticClass:"ma-0",attrs:{"dense":"","mandatory":""},model:{value:(_vm.displayMode),callback:function ($$v) {_vm.displayMode=$$v},expression:"displayMode"}},[_c('v-btn',{attrs:{"value":"grid"}},[_c('v-icon',[_vm._v("mdi-view-module-outline")])],1),_c('v-btn',{attrs:{"value":"list"}},[_c('v-icon',[_vm._v("mdi-view-list-outline")])],1)],1)],1),_c('v-scroll-x-transition',{attrs:{"leave-absolute":""}},[(_vm.displayMode === 'list')?_c('v-data-table',{staticClass:"full-width",attrs:{"loading":_vm.throttledLoading,"headers":[
          {value: 'type', width: 1, filterable: false, sortable: false},
          {text: 'Name', value: 'basename'},
          {text: 'Size', value: 'size'} ],"options":_vm.paginationOptions,"footer-props":{
          'items-per-page-options': [30,50,100,-1]
        },"items":_vm.items,"item-class":function () { return 'hand-cursor'; }},on:{"click:row":_vm.navigateToItem},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})]}},{key:"item.size",fn:function(ref){
        var item = ref.item;
return [(item.type === 'file')?[_vm._v(" "+_vm._s(_vm.formatBytes(item.size))+" ")]:_vm._e()]}}],null,false,1276345397)}):_c('v-data-iterator',{staticClass:"full-width",attrs:{"loading":_vm.throttledLoading,"options":_vm.paginationOptions,"items":_vm.items,"footer-props":{
          'items-per-page-options': [30,50,100,-1]
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-fade-transition',[_c('div',{staticClass:"pa-3 text-center title"},[_vm._v(" No files ")])])]},proxy:true},{key:"default",fn:function(ref){
        var items = ref.items;
return [_c('transition',{attrs:{"name":_vm.lastNavigation === 'up' ? 'scroll-y-reverse-transition' : 'scroll-y-transition',"mode":"out-in"}},[(!_vm.loading)?_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"explorer-grid"},_vm._l((items),function(item){return _c('library-node-view',{key:item.path,attrs:{"node":item},on:{"click":function($event){return _vm.navigateToItem(item)}}})}),1)]):_vm._e()])]}}])})],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.documentViewerDialogOpen),callback:function ($$v) {_vm.documentViewerDialogOpen=$$v},expression:"documentViewerDialogOpen"}},[(!!_vm.documentViewerNode)?_c('div',{staticClass:"fill-height d-flex flex-column"},[_c('v-toolbar',{staticClass:"flex-grow-0",attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.documentViewerNodeDisplayName))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.documentViewerDialogOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('document-viewer',{staticClass:"flex-grow-1",attrs:{"library-id":_vm.library.id,"node":_vm.documentViewerNode,"loading":_vm.loading,"page":_vm.documentViewerPage},on:{"delete":function (permanently) { return _vm.deleteNode(_vm.documentViewerNode, permanently); },"request-update":_vm.fetch}})],1):_vm._e()]),_c('v-snackbar',{attrs:{"top":"","right":"","timeout":5000},model:{value:(_vm.snackbarOpen),callback:function ($$v) {_vm.snackbarOpen=$$v},expression:"snackbarOpen"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }